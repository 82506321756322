<template>
    <div :class="isPCH5Text !== 'PC' ? 'Shop_ApprovalInfoList' : 'Shop_ApprovalInfoList_PC'">
        <div style="" :class="isPCH5Text !== 'PC' ? 'Shop_ApprovalInfoList_bottom' : 'Shop_ApprovalInfoList_bottom_PC'">
            <div style="width: 50%;text-align: center;border-right: 1px solid #ccc;box-sizing: border-box;">
                <i class="el-icon-tickets" style="font-size: 30px;color: #D1100B;"></i>
            </div>
            <div style="width: 50%;text-align: center;" @click="$router.push('./RG_UserInfo')">
                <i class="el-icon-user-solid" style="font-size: 30px;"></i>
            </div>
        </div>
        <div style="padding: 10px;">
            <el-tabs v-model="activeName" type="border-card" stretch>
                <el-tab-pane label="待审列表" name="待审列表" v-loading="loading">
                    <div
                        style="position: sticky;top: 0;left: 0;height: 50px;line-height: 50px;background-color: #fff;z-index: 99;">
                        <el-input placeholder="请输入内容" v-model="SerachValue">
                            <el-select v-model="SerachSelect" slot="prepend" placeholder="请选择" style="width: 110px;">
                                <el-option label="项目名称" value="ProjectName"></el-option>
                                <el-option label="任务名称" value="TaskName"></el-option>

                                <el-option label="报审标题" value="ApprovalName"></el-option>
                            </el-select>
                            <template slot="append">
                                <i class="el-icon-search" @click="search"></i>
                            </template>
                        </el-input>
                    </div>
                    <div style="border: 1px solid #ccc;padding: 10px;margin-top: 5px;" v-for="(item, index) in dataList"
                        :key="index" @click="clickItem(item)">
                        <div style="display: flex;align-items: center;position: relative;z-index: 1;">
                            <div style="width: calc(100% - 20px);">
                                <div style="font-size: 16px;font-weight: bold;text-align: center;line-height: 30px;"
                                    class="ellipsis" :title="item.ApprovalName">{{
                                        item.ApprovalName
                                    }}</div>
                                <div style="line-height: 30px;font-size: 14px;">
                                    <div class="ellipsis">
                                        编号：{{ item.ApprovalCode }}
                                    </div>
                                    <div class="ellipsis">
                                        项目：{{ item.ProjectName }}
                                    </div>
                                    <div class="ellipsis">
                                        任务：{{ item.TaskName }}
                                    </div>

                                    <div class="ellipsis">
                                        第 <span style="color: red;">{{ item.ApprovalBatch }}</span> 批次
                                    </div>
                                </div>
                                <div
                                    style="display: flex;justify-content: space-between;line-height: 30px;align-items: center;">
                                    <el-tag effect="plain" size="mini">{{
                                        item.ApprovalType == 1 ? '设备材料' : item.ApprovalType == 2 ? '深化图纸' : ''
                                    }}</el-tag>
                                    <div style="font-size: 12px;">{{ item.CreateTime ? item.CreateTime.substring(0, 19)
                                        : ""
                                        }}</div>
                                </div>
                            </div>
                            <div style="width: 20px;">
                                <i class="el-icon-arrow-right" style="font-size: 30px;width: 20px;"></i>
                            </div>

                            <div style="position: absolute;right: -13px;top: -14px;z-index: 2;" v-if="item.IsNew == 0">
                                <el-image style="width: 50px; height: 50px" :src="newImgUrl" fit="cover"></el-image>
                            </div>
                        </div>



                    </div>
                    <el-empty description="暂无数据" v-if="dataList.length == 0"></el-empty>
                    <div style="height: 10px;"></div>
                </el-tab-pane>
                <el-tab-pane label="已审列表" name="已审列表" v-loading="loading">
                    <div style="position: sticky;top: 0;left: 0;height: 50px;line-height: 50px;background-color: #fff;">
                        <el-input placeholder="请输入内容" v-model="SerachValue2">
                            <el-select v-model="SerachSelect2" slot="prepend" placeholder="请选择" style="width: 110px;">
                                <el-option label="项目名称" value="ProjectName"></el-option>
                                <el-option label="任务名称" value="TaskName"></el-option>

                                <el-option label="报审标题" value="ApprovalName"></el-option>
                            </el-select>
                            <template slot="append">
                                <i class="el-icon-search" @click="searc2"></i>
                            </template>
                        </el-input>
                    </div>
                    <div style="border: 1px solid #ccc;padding: 10px;margin-top: 5px;"
                        v-for="(item, index) in ReviewedDataList" :key="index" @click="clickItem(item, 1)">
                        <div style="display: flex;align-items: center;">
                            <div style="width: calc(100% - 20px);">
                                <div style="font-size: 16px;font-weight: bold;text-align: center;line-height: 30px;"
                                    class="ellipsis" :title="item.ApprovalName">{{
                                        item.ApprovalName
                                    }}</div>
                                <div style="line-height: 30px;font-size: 14px;">
                                    <div class="ellipsis">
                                        项目：{{ item.ProjectName }}
                                    </div>
                                    <div class="ellipsis">
                                        任务：{{ item.TaskName }}
                                    </div>
                                    <div class="ellipsis">
                                        第 <span style="color: red;">{{ item.ApprovalBatch }}</span> 批次
                                    </div>
                                </div>
                                <div
                                    style="display: flex;justify-content: space-between;line-height: 30px;align-items: center;">
                                    <el-tag effect="plain" size="mini">{{
                                        item.ApprovalType == 1 ? '设备材料' : item.ApprovalType == 2 ? '深化图纸' : ''
                                    }}</el-tag>
                                    <div style="font-size: 12px;">{{ item.CreateTime.substring(0, 19)
                                        }}</div>
                                </div>
                            </div>
                            <div style="width: 20px;">
                                <i class="el-icon-arrow-right" style="font-size: 30px;width: 20px;"></i>
                            </div>
                        </div>



                    </div>
                    <div style="height: 10px;"></div>
                    <el-empty description="暂无数据" v-if="ReviewedDataList.length == 0"></el-empty>
                </el-tab-pane>

                <el-tab-pane label="消息" name="消息" v-loading="loadingMsg">
                    <span slot="label">
                        <span style="display: flex;align-items: center;justify-content: center">
                            <span>消息</span>
                            <span
                                style="display: inline-block;background-color: #f56c6c;color: #fff;height: 16px;line-height: 16px;border-radius: 10px;padding: 0 5px;font-size: 10px;box-sizing: border-box;"
                                v-if="msgCount > 0">{{
                                    msgCount > 99 ? '99+' : msgCount
                                }}</span>
                        </span>
                    </span>
                    <div
                        style="display: flex;align-items: center;justify-content: space-between;line-height: 24px;padding: 10px 5px;border-bottom:1px solid #ccc;margin-bottom: 10px;">
                        <span style="font-size: 14px;">当前手机号：{{ userInfo.Phone }}</span>
                        <el-button :style="`padding: 5px;color:${msgCount != 0 ? '#3370ff' : ''}`" type="text"
                            @click="UPSendMsg" :disabled="msgCount == 0">全部设为已读</el-button>
                    </div>
                    <div style="height:calc(100vh - 226px);overflow-y: auto;padding: 0 5px;">
                        <div v-for="item in SendMsgList" :key="item.Id"
                            style="position: relative;margin-top: 5px;margin-bottom: 5px;" @click="clickSendMsg(item)">
                            <el-card>
                                <span style="font-size: 14px;line-height: 1.6;"> {{ item.MsgInfo
                                    }}</span>
                                <el-tag size="small" type="success" style="position: absolute;right: 0px;top: 0px;"
                                    v-if="item.IsBeenRead">已读</el-tag>
                                <el-tag size="small" style="position: absolute;right: 0px;top: 0px;"
                                    v-if="!item.IsBeenRead">未读</el-tag>
                            </el-card>
                        </div>
                    </div>
                    <div
                        style="height: 40px;width: 100%;text-align: center;display: flex;align-items: center;justify-content: center">
                        <el-pagination layout="prev, pager, next,total" :total="pagination.total" background small
                            :page-size="pagination.pageSize" :page-count="pagination.pageCount"
                            @size-change="handleSizeChange" @current-change="handleCurrentChange">
                        </el-pagination>
                    </div>

                </el-tab-pane>
            </el-tabs>


        </div>
    </div>
</template>
<script>
import newImgUrl from '@/assets/NewRightSup_500.png';
export default {
    data() {
        return {
            newImgUrl,
            dataList: [],
            SerachSelect: 'ApprovalName',
            SerachValue: '',
            SerachSelect2: 'ApprovalName',
            SerachValue2: '',
            loading: false,
            ReviewedDataList: [],
            activeName: '待审列表',
            userInfo: {},
            isPCH5Text: '',
            SendMsgList: [],
            pagination: {
                currentPage: 1,
                pageSize: 10,
                total: 0,
            },
            msgCount: 0,
            loadingMsg: false,
        };
    },
    props: {},
    components: {},
    methods: {
        GetMsgUnreadCount() {
            this.$http.post('/ReviewGO/Review_ApprovalMsg/GetMsgUnreadCount').then(res => {
                if (res.Success) {
                    this.msgCount = res.Data || 0
                } else {
                    this.$message.error(res.Msg);
                }
            })
        },

        UPSendMsg() {
            this.$http.post('/ReviewGO/Review_ApprovalMsg/UpdateReadingStatusAll').then(res => {
                if (res.Success) {
                    this.getSendMsg()
                } else {
                    this.$message.error(res.Msg);
                }
            })
        },
        handleSizeChange(val) {
            this.pagination.pageSize = val
            this.getSendMsg()

        },
        handleCurrentChange(val) {
            this.pagination.currentPage = val;
            this.getSendMsg()
        },
        clickSendMsg(item) {
            if (item.IsBeenRead) {
                return
            }
            this.$http.post('/ReviewGO/Review_ApprovalMsg/UpdateReadingStatus', {
                id: item.Id
            }).then(res => {
                if (res.Success) {
                    this.getSendMsg()
                } else {
                    this.$message.error(res.Msg);
                }
            })
        },
        //消息
        getSendMsg() {
            this.GetMsgUnreadCount()
            this.loadingMsg = true
            this.$http
                .post("/ReviewGO/Review_ApprovalMsg/GetDataListByMoreCondition", {
                    PageIndex: this.pagination.currentPage,
                    PageRows: this.pagination.pageSize,
                    SortField: "CreateTime",
                    SortType: "desc",
                    Search: [
                        {
                            Condition: "EU_Id",
                            Keyword: this.userInfo.Id,
                        },

                    ],
                })
                .then(res => {
                    this.loadingMsg = false
                    if (res.Success) {
                        this.SendMsgList = res.Data || [];
                        this.pagination.total = res.Total;

                    }
                })
        },
        search() {
            this.getDataListByPhoneForUnreviewed()
        },
        searc2() {
            this.getDataListByPhoneForReviewed()
        },
        getDataListByPhoneForUnreviewed() {
            this.loading = true
            let obj = {}
            if (this.SerachSelect) {
                obj = {
                    "Condition": this.SerachSelect,
                    "Keyword": this.SerachValue
                }
            }
            this.$http.post('/ReviewGO/Review_ApprovalInfo/GetDataListByPhoneForUnreviewed', {
                // "PageIndex": 0,
                // "PageRows": 0,
                SortField: "CreateTime",
                SortType: "desc",
                "Search": [
                    { ...obj },
                    {
                        "Condition": "ApprovalPhone",
                        "Keyword": this.userInfo.Phone
                    }
                ]
            }).then(res => {
                this.loading = false
                if (res.Success) {
                    this.dataList = res.Data || [];
                } else {
                    this.$phoneMessage(res.Msg);
                }
            })
        },
        getDataListByPhoneForReviewed() {

            this.loading = true
            let obj = {}
            if (this.SerachSelect) {
                obj = {
                    "Condition": this.SerachSelect2,
                    "Keyword": this.SerachValue2
                }
            }
            this.$http.post('/ReviewGO/Review_ApprovalInfo/GetDataListByPhoneForReviewed', {
                // "PageIndex": 0,
                // "PageRows": 0,
                SortField: "CreateTime",
                SortType: "desc",
                "Search": [
                    { ...obj },
                    {
                        "Condition": "ApprovalPhone",
                        "Keyword": this.userInfo.Phone
                    }
                ]
            }).then(res => {
                this.loading = false
                if (res.Success) {
                    this.ReviewedDataList = res.Data || [];
                } else {
                    this.$phoneMessage(res.Msg);
                }
            })
        },
        clickItem(item, num) {
            this.$router.push({
                path: '/RG_ApprovalInfoDetail',
                query: {
                    Id: item.Id,
                    num: num || 0,
                    ApprovalType: item.ApprovalType,
                    TaskId: item.TaskId,
                }
            })
        },
        getSystemInfo() {
            if (/(micromessenger)/i.test(navigator.userAgent)) {
                return "微信"
            } else {
                // 判断h5还是pc true就是h5
                let client =
                    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                        navigator.userAgent
                    );
                if (client) {
                    return "H5"
                } else {
                    return "PC"
                }
            }
        },
    },
    mounted() { },
    created() {
    },
    activated() {
        // console.log(navigator.userAgent, '1111111')

        this.userInfo = window.localStorage.getItem('RGInfo') ? JSON.parse(window.localStorage.getItem('RGInfo')) : {};
        this.getDataListByPhoneForReviewed()
        this.getDataListByPhoneForUnreviewed()
        this.isPCH5Text = this.getSystemInfo()
        window.addEventListener('resize', () => {
            this.isPCH5Text = this.getSystemInfo()
        })
        this.getSendMsg()

    },
    beforeDestroy() {
        window.removeEventListener('resize', () => {
            this.isPCH5Text = this.getSystemInfo()
        })
    },
    computed: {},
    watch: {
        activeName(val) {
            console.log(val)
            if (val === '待审列表') {
                this.getDataListByPhoneForUnreviewed()
            } else if (val === '已审列表') {
                this.getDataListByPhoneForReviewed()
            } else if (val === '消息') {
                this.getSendMsg()
            }
        }
    },
};
</script>
<style lang='scss' scoped>
.Shop_ApprovalInfoList {
    width: 100vw;
    height: 100vh;
    // padding: 10px;
    box-sizing: border-box;
    background: #eccece;
    position: relative;
}

::v-deep .el-tabs__content {
    height: calc(100vh - 125px);
    overflow-y: auto;

    position: relative;
    padding: 0 10px;
    box-sizing: border-box;
}

.ellipsis {
    overflow: hidden; //超出隐藏
    word-wrap: break-word;
    word-break: normal;
    text-overflow: ellipsis; //文本超出时显示省略号

    display: -webkit-box;

    -webkit-box-orient: vertical; //子元素排列 vertical（竖排）orhorizontal（横排）

    -webkit-line-clamp: 1; //内容限制的行数
}

.Shop_ApprovalInfoList_bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 100vw;
    background: #fff;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-top: 1px solid #ccc;
}

.Shop_ApprovalInfoList_PC {
    width: 100vw;
    padding: 0 30vw;
    height: 100vh;
    // padding: 10px;
    box-sizing: border-box;
    background: #eccece;
    position: relative;
}

.Shop_ApprovalInfoList_bottom_PC {
    position: fixed;
    bottom: 0;
    left: calc(30vw + 10px);
    height: 60px;
    width: calc(40vw - 20px);
    background: #fff;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-top: 1px solid #ccc;
}
</style>